import React from 'react'

const pubs = [
  "<b>De-Yuan Lu</b> and Lung-Pan Cheng. 2024. FisheyeVR: Extending the Field of View by Dynamic Zooming in Virtual Reality. In The 37th Annual ACM Symposium on User Interface Software and Technology (UIST Adjunct '24), October 13--16, 2024, Pittsburgh, PA, USA. ACM, New York, NY, USA 3 Pages. <a href=\"https://doi.org/10.1145/3672539.3686316\" target=\"_blank\">https://doi.org/10.1145/3672539.3686316</a>",
]

const Publications = () => {
  return (
    <div>
      <div className="flex flex-col mx-auto pt-4 md:pt-12 px-6 md:px-24 lg:px-36 xl:px-48">
        {
          pubs.map((pub, i) => {
            return (
              <div key={i} className="flex flex-row w-full items-center gap-x-4 md:gap-x-8">
                <h1 className="font-bold text-xl">{i + 1}</h1>
                <div
                  className="[&>a]:underline"
                  dangerouslySetInnerHTML={{ __html: pub }}>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Publications